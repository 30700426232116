<template>
  <label class="base-checkbox">
    <input
      v-if="value"
      class="base-checkbox-input"
      v-model="formValue"
      :value="value"
      type="checkbox"
    />
    <input v-else class="base-checkbox-input" v-model="formValue" type="checkbox" />
    <span class="base-checkbox-slider" />
    <span class="base-checkbox-label">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: ["modelValue", "label", "value"],
  computed: {
    formValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.base-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.base-checkbox-label {
  margin-left: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #111111;
}

.base-checkbox-slider {
  background-color: #fff;
  border: 2px solid #f0f1f4;
  transition: 0.4s;
  display: block;
  width: 48px;
  flex: 0 0 48px;
  height: 24px;
  border-radius: 100px;
  position: relative;
}

.base-checkbox-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  background-color: #f0f1f4;
  border-radius: 50%;
  transition: 0.4s;
}

.base-checkbox-input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.base-checkbox-input:checked + .base-checkbox-slider {
  background-color: #0ba3a9;
  border-color: transparent;
}

.base-checkbox-input:focus + .base-checkbox-slider:before {
  box-shadow: 0 0 1px #0ba3a9;
}

.base-checkbox-input:checked + .base-checkbox-slider:before {
  transform: translateX(22px);
  background-color: #fff;
}
</style>
