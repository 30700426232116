<template>
  <div v-if="isVisible" class="change-phone">
    <div class="change-phone-background" @click="handleCancel" />
    <!-- Step 0 -->
    <div v-if="step === 0" class="change-phone-content">
      <div class="change-phone-title">
        By changing the phone number you will be creating a new subscriber account and migrating any
        previous history across. Both the previous phone number and the new number will receive an
        SMS notification. Are you sure you want to do this?
      </div>
      <div class="change-phone-row">
        <BaseButton theme="error" @click="handleCancel">Cancel</BaseButton>
        <BaseButton @click="handleConfirm">Confirm</BaseButton>
      </div>
    </div>

    <!-- Step 1 -->

    <div v-if="step === 1" class="change-phone-content">
      <BaseInput v-model="formValue" placeholder="Enter phone number" />
      <div class="change-phone-row">
        <BaseButton theme="error" @click="handleCancel">Cancel</BaseButton>
        <BaseButton @click="handleConfirm">Change</BaseButton>
      </div>
    </div>

    <!-- Step 2 -->

    <div v-if="step === 2" class="change-phone-content">
      <div v-loading="step === 2" class="change-phone-loading" />
      <div class="change-phone-title">Transferring account to new mobile number.</div>
    </div>

    <!-- Step 3 -->

    <div v-if="step === 3" class="change-phone-content">
      <div class="change-phone-title">
        Subscriber account transferred from <b>{{ currentPhoneNumber }}</b> to
        <b>{{ modelValue }}</b>
      </div>
      <BaseButton @click="handleCancel">Finish</BaseButton>
    </div>
  </div>
</template>

<script>
import BaseInput from "../BaseInput";
import BaseButton from "../BaseButton";

export default {
  name: "ChangePhone",
  components: {
    BaseInput,
    BaseButton,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    phoneNumber: {
      type: String,
    },
    isVisible: {
      type: Boolean,
    },
    step: {
      type: Number,
    },
  },
  data() {
    return {
      currentPhoneNumber: "",
    };
  },
  computed: {
    formValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    handleConfirm() {
      this.$emit("confirm");
    },
  },
  mounted() {
    this.currentPhoneNumber = JSON.parse(JSON.stringify(this.phoneNumber));
  },
  watch: {
    isVisible: {
      handler() {
        this.currentPhoneNumber = JSON.parse(JSON.stringify(this.phoneNumber));
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.change-phone {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;

  .change-phone-background {
    background: rgba(166, 170, 180, 0.6);
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .change-phone-content {
    width: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 200px;
    background: #fff;
    border: 1px solid rgba(166, 170, 180, 0.5);
    padding: 32px 24px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 24px;
  }

  .change-phone-title {
    flex: 1;
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.1px;
  }

  .change-phone-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;
  }

  .change-phone-loading {
    width: 40px;
    height: 40px;
  }

  b {
    font-weight: 500;
  }
}
</style>
