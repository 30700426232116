<template>
  <BaseModal :loading="isLoading" @close="$router.push('/subscribers')">
    <div class="subscriber-editor">
      <ChangePhone
        v-model="new_phone_number"
        :is-visible="isPhoneChangeModalVisible"
        :step="phoneChangeModalStep"
        :phone-number="subscriber.phone_number"
        @cancel="handlePhoneChangeModalCancel"
        @confirm="handlePhoneChangeModalConfirm"
      />
      <div class="subscriber-editor-title">Subscriber details</div>
      <div class="subscriber-editor-row">
        <div class="subscriber-editor-personal">
          <div class="subscriber-editor-row">
            <BaseInput v-model="subscriber.first_name" placeholder="First name" />
            <BaseInput v-model="subscriber.last_name" placeholder="Last name" />
          </div>
          <div class="subscriber-editor-phone">
            <BaseInput
              v-model="subscriber.phone_number"
              :disabled="uid"
              placeholder="Phone number"
            />
            <div v-if="uid" class="subscriber-editor-phone-action" @click="handlePhoneEdit">
              Edit
            </div>
          </div>
          <div
            v-if="subscriber.phone_number !== '' && !validatePhone(subscriber.phone_number)"
            class="error-block"
          >
            Please, type correct phone number
          </div>
          <BaseInput v-model="subscriber.email" placeholder="Email" />
          <div
            v-if="subscriber.email !== '' && !validateEmail(subscriber.email)"
            class="error-block"
          >
            Please, type correct email
          </div>
          <div class="subscriber-editor-row" v-if="subscriber">
            <BaseDatePicker v-model="subscriber.date_of_birth" placeholder="Date of birth" />
            <BaseSelect
              v-model="subscriber.gender"
              :options="gender_options"
              placeholder="Gender"
            />
          </div>
        </div>
        <!-- <div class="subscriber-editor-col" v-if="subscriber.subscription">
          <div class="subscriber-editor-level" :class="{ disabled: uid }">
            <div class="subscriber-editor-level-title">Subscription</div>
            <label
              v-for="(subscription, index) in clubSubscriptions"
              :key="`subscription-${index}`"
              class="subscriber-editor-radio"
            >
              <BaseRadio
                v-model="subscriber.subscription.subscription_id"
                :label="`${subscription.name} <b>($${subscription.price})</b>`"
                :value="subscription.id"
              />
            </label>
          </div>
        </div> -->
      </div>
      <div class="subscriber-editor-divider" />
      <!-- <div class="subscriber-editor-checkboxes">
        <div class="subscriber-editor-row">
          <BaseCheckbox
            v-model="club_specific.adhoc"
            label="Subscriber has opted in for adhoc contact"
          />
          <BaseCheckbox
            v-model="club_specific.active"
            label="Subscriber account is active"
          />
        </div>
        <div class="subscriber-editor-row">
          <BaseCheckbox
            v-model="club_specific.marketing"
            label="Subscriber has opted in for marketing contact"
          />
        </div>
      </div> -->
      <div class="subscriber-editor-additional">
        <div
          v-if="club?.medical_conditions?.length > 0 || club?.allergies?.length > 0"
          class="subscriber-editor-title"
        >
          Subscriber medical information
        </div>
        <div class="subscriber-editor-row-2 subscriber-editor-margin">
          <div v-if="club?.medical_conditions?.length > 0" class="subscriber-editor-card">
            <div class="subscriber-editor-card-title">Medical conditions</div>
            <div
              class="subscriber-editor-card-input"
              v-for="(option, index) in club.medical_conditions"
              :key="`medical-option-${index}`"
            >
              <BaseCheckbox
                :label="option"
                :modelValue="
                  subscriber.club_specific?.medical_conditions?.includes(option) ?? false
                "
                @update:modelValue="handleMedicalChange(option, $event)"
              />
            </div>
          </div>
          <div v-if="club?.allergies?.length > 0" class="subscriber-editor-card">
            <div class="subscriber-editor-card-title">Allergies</div>
            <div
              class="subscriber-editor-card-input"
              v-for="(option, index) in club.allergies"
              :key="`medical-option-${index}`"
            >
              <BaseCheckbox
                :label="option"
                :modelValue="subscriber.club_specific?.allergies?.includes(option) ?? false"
                @update:modelValue="handleAllergyChange(option, $event)"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Questions and answers -->
      <div class="subscriber-editor-title">Subscriber questions & answers</div>
      <el-empty v-if="computedQuestions.length === 0" description="No questions" />
      <div v-else class="subscriber-editor-questions">
        <div
          v-for="(question, index) in computedQuestions"
          :key="`question-${index}`"
          class="subscriber-editor-questions-item"
        >
          <span>{{ question.label }}</span>
          <BaseInput v-model="computedQuestions[index].value" />
        </div>
      </div>

      <!-- Subscription details -->
      <template v-if="uid && thisUser">
        <div class="subscriber-editor-title">Subscription details</div>
        <table class="stats-table">
          <tr
            class="stats-table__row"
            v-for="(row, rowIndex) in subscriberStatsTable"
            :key="rowIndex"
          >
            <td
              class="stats-table__col stats-table__title"
              :class="row[0].class"
              v-html="row[0].text"
            />
            <td
              class="stats-table__col stats-table__text"
              :class="row[1].class"
              v-html="row[1].text"
            />
          </tr>
        </table>
      </template>

      <!-- SMS history -->
      <template v-if="uid && thisUser">
        <div class="subscriber-editor-title sms-title">SMS history</div>
        <div class="no-sms-messages" v-if="smsMessages.length === 0">
          There were no SMS messages sent to this user.
        </div>
        <table class="stats-table" v-else>
          <tr class="stats-table__head">
            <th
              class="stats-table__col stats-table__heading filter"
              :class="{ 'is-ascending': !smsSortDescending }"
              @click="smsSortDescending = !smsSortDescending"
            >
              Date sent
            </th>
            <th class="stats-table__col stats-table__heading">Message text</th>
          </tr>
          <transition-group name="flip-list">
            <tr class="stats-table__row" v-for="row in computedSMSMessages" :key="row[0].text">
              <td
                class="stats-table__col stats-table__title"
                :class="row[0].class"
                v-html="row[0].text"
              />
              <td
                class="stats-table__col stats-table__text"
                :class="row[1].class"
                v-html="row[1].text"
              />
            </tr>
          </transition-group>
        </table>
      </template>

      <!-- Buttons -->
      <div class="subscriber-editor-button" :class="{ 'two-buttons': uid }">
        <BaseButton theme="error" v-if="uid" @click="handleDelete">Delete user</BaseButton>
        <BaseButton @click="applyChange" :disabled="disableButton">Apply changes</BaseButton>
      </div>
      <div v-if="errorText" class="error-text">{{ errorText }}</div>
    </div>
  </BaseModal>
</template>

<script>
import firebase from "firebase";
import { firebaseInstance } from "@/config/firebase";

import BaseSelect from "@/components/BaseSelect.vue";
import BaseDatePicker from "@/components/BaseDatePicker.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
// import BaseRadio from "@/components/BaseRadio.vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseCheckbox from "../components/BaseCheckbox";

import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";

import ChangePhone from "../components/SubscriberEditor/ChangePhone";
import { ReplaceSmartTagsWithContent } from "../components/ApplyAction/smartTags";
import moment from "moment";

export default {
  name: "SubscriberEditor",
  components: {
    BaseCheckbox,
    BaseSelect,
    BaseDatePicker,
    BaseInput,
    BaseButton,
    // BaseRadio,
    BaseModal,
    ChangePhone,
  },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      isLoading: false,
      phoneChangeModalConfirmed: false,
      phoneChangeModalStep: 0,
      isPhoneChangeModalVisible: false,
      new_phone_number: "",
      errorText: "",
      subscriber: {
        phone_number: "",
        email: "",
        first_name: "",
        last_name: "",
        date_of_birth: null,
        gender: "",
        clubs: [],
        club_specific: {
          questions: [],
          allergies: [],
          medical_conditions: [],
        },
        subscription: {
          subscription_id: "",
        },
      },
      gender_options: [
        { label: "Rather not say", value: "not-provided" },
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
      smsMessages: [],
      smsSortDescending: true,
    };
  },
  computed: {
    ...mapGetters(["club", "clubId", "clubSubscriptions", "users"]),
    disableButton() {
      const subscriberSettings = JSON.parse(JSON.stringify(this.subscriber));
      const clubSpecificSettings = subscriberSettings?.club_specific ?? {};
      delete subscriberSettings.club_specific;
      delete subscriberSettings.subscription;
      delete subscriberSettings.clubs;
      delete clubSpecificSettings.questions;
      delete clubSpecificSettings.allergies;
      delete clubSpecificSettings.medical_conditions;

      const anySubscriberVerifications =
        Object.values(subscriberSettings).every((value) => value?.length !== 0) &&
        Object.values(clubSpecificSettings).every((value) => value?.length !== 0) &&
        this.validatePhone(this.subscriber.phone_number) &&
        this.validateEmail(this.subscriber.email) &&
        this.computedQuestions
          .filter((question) => question.required)
          .every((value) => value.value.length !== 0);

      return !anySubscriberVerifications;
    },
    uid() {
      return this.$route.params.id;
    },
    computedQuestions() {
      return this.subscriber.club_specific?.questions ?? [];
    },
    thisUser() {
      return this.uid ? this.$store.getters.getUserById(this.uid) : null;
    },
    subscriberStatsTable() {
      return [
        [
          {
            class: "",
            text: "Active subscription",
          },
          {
            class: "",
            text: this.subscriber.subscription
              ? ReplaceSmartTagsWithContent(this, this.subscriber, "[SUBSCRIBER_PRODUCT]")
              : "(no subscription)",
          },
        ],
        [
          {
            class: "",
            text: "Expires at",
          },
          {
            class: "",
            text: ReplaceSmartTagsWithContent(this, this.subscriber, "[SUBSCRIPTION_EXPIRY_DATE]"),
          },
        ],
        [
          {
            class: "",
            text: "Subscription amount",
          },
          {
            class: "",
            text: ReplaceSmartTagsWithContent(this, this.subscriber, "[SUBSCRIPTION_AMOUNT]"),
          },
        ],
        [
          {
            class: "",
            text: "Billing date",
          },
          {
            class: "",
            text: ReplaceSmartTagsWithContent(this, this.subscriber, "[SUBSCRIPTION_BILLING_DATE]"),
          },
        ],
        [
          {
            class: "",
            text: "Last billing date",
          },
          {
            class: "",
            text: ReplaceSmartTagsWithContent(
              this,
              this.subscriber,
              "[SUBSCRIPTION_LAST_BILLING_DATE]"
            ),
          },
        ],
        [
          {
            class: "",
            text: "Total billings",
          },
          {
            class: "",
            text: ReplaceSmartTagsWithContent(this, this.subscriber, "[TOTAL_BILLINGS]"),
          },
        ],
      ];
    },
    computedSMSMessages() {
      return [...this.smsMessages]
        .sort((a, b) =>
          !this.smsSortDescending ? a.datestamp - b.datestamp : b.datestamp - a.datestamp
        )
        .map((message) => {
          return [
            {
              class: "",
              text: moment(message.datestamp).format("YYYY/MM/DD HH:mm:ss"),
            },
            {
              class: "",
              text: message.message,
            },
          ];
        });
    },
  },
  mounted() {
    console.log(firebase.firestore.Timestamp.now().seconds * 1000);
    console.log(firebase.firestore.Timestamp.now());
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validatePhone(phone) {
      let re = /^\+?[1-9]\d{1,14}$/;
      return re.test(phone);
    },
    async handlePhoneChangeModalConfirm() {
      const updateUserInfo = await firebase.functions().httpsCallable("updateUserInfo");
      this.phoneChangeModalConfirmed = true;
      if (this.phoneChangeModalStep === 0 && this.phoneChangeModalConfirmed) {
        this.phoneChangeModalStep = 1;
        this.phoneChangeModalConfirmed = false;
      }

      if (this.phoneChangeModalStep === 1 && this.phoneChangeModalConfirmed) {
        this.phoneChangeModalStep = 2;
        this.phoneChangeModalConfirmed = false;
      }

      if (this.phoneChangeModalStep === 2) {
        const entry = await updateUserInfo({
          user_id: this.uid,
          club_id: this.clubId,
          user_info: {
            phone_number: this.new_phone_number,
          },
        });

        if (entry.data.isSuccess) {
          this.phoneChangeModalStep = 3;

          if (entry.data.problems && entry.data.problems.length > 0) {
            entry.data.problems.forEach((problem) => {
              this.toast.error(problem.info);
            });
          }

          if (entry.data.error) {
            this.toast.error(entry.data.error.message);
          }
        } else {
          this.handlePhoneChangeModalCancel();
          console.log(entry.data.error.message);
          this.toast.error(
            "Sorry, phone change was not successful, please try again or contact support"
          );
        }
      }
    },
    handlePhoneChangeModalCancel() {
      this.isPhoneChangeModalVisible = false;
      this.phoneChangeConfirmed = false;
      this.new_phone_number = "";
      this.phoneChangeModalStep = 0;
    },
    handlePhoneEdit() {
      this.isPhoneChangeModalVisible = true;
    },
    handleAllergyChange(option, value) {
      value === true
        ? this.subscriber.club_specific.allergies.push(option)
        : (this.subscriber.club_specific.allergies = [
            ...this.subscriber.club_specific.allergies,
          ].filter((allergy) => allergy !== option));
    },
    handleMedicalChange(option, value) {
      value === true
        ? this.subscriber.club_specific.medical_conditions.push(option)
        : (this.subscriber.club_specific.medical_conditions = [
            ...this.subscriber.club_specific.medical_conditions,
          ].filter((allergy) => allergy !== option));
    },
    async getClubData() {
      // Parse club questions
      this.subscriber.club_specific.questions =
        this.club?.questions?.map((question, i) => ({
          required: question.required,
          label: question.text,
          value:
            this.subscriber.club_specific.questions?.[i]?.value?.length > 0
              ? this.subscriber.club_specific.questions[i].value
              : "",
        })) ?? [];
    },
    async getSubscriber() {
      const subscriberData = this.thisUser;
      if (subscriberData && this.users.length > 0) {
        this.subscriber = {
          ...this.subscriber,
          ...JSON.parse(JSON.stringify(subscriberData)),
        };
      }

      try {
        const getSMSMessages = await firebaseInstance
          .firestore()
          .collection("clubs")
          .doc(this.clubId)
          .collection("messages")
          .where("subscriber_id", "==", this.uid)
          .get();

        if (!getSMSMessages.empty) {
          this.smsMessages = await Promise.all(
            getSMSMessages.docs.map(async (doc) => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            })
          );
        }
      } catch (error) {
        console.log(error);
      }

      console.log(this.subscriber);
    },
    async applyChange() {
      this.isLoading = true;
      this.errorText = "";
      const createUsers = await firebaseInstance.functions().httpsCallable("createUsers");

      if (this.uid) {
        const subscriber = JSON.parse(JSON.stringify(this.subscriber));
        delete subscriber.club_specific;
        try {
          await firebaseInstance.firestore().collection("users").doc(this.uid).update(subscriber);
          await firebaseInstance
            .firestore()
            .collection("users")
            .doc(this.uid)
            .collection("club_specific")
            .doc(this.clubId)
            .update(this.subscriber.club_specific);
        } catch (error) {
          this.toast.error(
            "Sorry, subscriber update was not successful, please try again or contact support"
          );
        }
      } else {
        const subscriber = JSON.parse(JSON.stringify(this.subscriber));
        delete subscriber.club_specific;
        delete subscriber.subscription;
        const entry = await createUsers([
          {
            email: this.subscriber.email,
            phoneNumber: this.subscriber.phone_number,
          },
        ]);

        if (entry.data.success.length > 0) {
          await firebaseInstance
            .firestore()
            .collection("users")
            .doc(entry.data.success[0].uid)
            .set({ ...subscriber });

          await firebaseInstance
            .firestore()
            .collection("users")
            .doc(entry.data.success[0].uid)
            .collection("club_specific")
            .doc(this.clubId)
            .set({
              ...this.subscriber.club_specific,
              join_date: firebase.firestore.Timestamp.now().seconds * 1000,
            });
        } else {
          this.toast.error(entry.data.failed[0].error.message);
        }

        if (entry.data.failed.length > 0) {
          this.errorText = "This user already exists! Please contact support.";
        }
      }

      this.isLoading = false;
      if (this.errorText.length === 0) this.$router.push("/subscribers");
    },
    async handleDelete() {
      const confirmed = confirm(
        `Are you sure you want to delete user ${this.subscriber.first_name} ${this.subscriber.last_name} (${this.subscriber.phone_number})?`
      );

      if (this.uid && confirmed) {
        this.isLoading = true;
        const deleteUser = await firebase.functions().httpsCallable("deleteUserFromClub");
        await deleteUser({
          user_id: this.uid,
          club_id: this.club.id,
          sub_id: this.club.id,
        })
          .then((result) => {
            console.log(result);
            if (result.data.isSuccess) {
              this.toast.success(
                "User was successfully deleted from the database, the list of subscribers will update in few seconds"
              );
              this.$router.push("/subscribers");
            } else {
              this.toast.error("Can't delete this user. Please contact support.");
            }
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      }
    },
  },
  created() {
    this.getClubData();
  },
  watch: {
    club: {
      handler() {
        this.getClubData();
      },
      immediate: true,
      deep: true,
    },
    thisUser: {
      handler() {
        if (this.uid) {
          this.getSubscriber();
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.subscriber-editor {
  .base-input,
  .base-date-picker,
  .base-select {
    margin-bottom: 25px;
  }

  .subscriber-editor-additional {
    margin-bottom: 39px;
  }

  .subscriber-editor-title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.5px;
    color: #0ba3a9;
    margin-bottom: 39px;
  }

  .subscriber-editor-personal {
    // flex: 0 0 55.5%;
    flex: 1;
  }

  .subscriber-editor-col {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 25px;
    gap: 32px;
  }

  .subscriber-editor-row {
    flex: 1;
    gap: 24px;
    display: flex;
    flex-flow: row nowrap;
  }

  .subscriber-editor-level {
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(166, 170, 180, 0.5);
    border-radius: 8px;
    padding: 24px;
    gap: 24px;
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
  }

  .subscriber-editor-level-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #0ba3a9;
    margin-bottom: 12px;
  }

  .subscriber-editor-level.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .subscriber-editor-divider {
    background: #e8e9ec;
    height: 1px;
    width: 100%;
    margin-top: 11px;
    margin-bottom: 32px;
  }

  .subscriber-editor-checkboxes {
    display: flex;
    flex-flow: column nowrap;
    gap: 32px 0;
    margin-bottom: 50px;
  }

  .subscriber-editor-checkboxes .base-checkbox {
    flex: 0 0 50%;
  }

  .subscriber-editor-questions {
    margin-bottom: 34px;
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
  }

  .subscriber-editor-questions-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 24px;
  }

  .subscriber-editor-questions-item input[type="text"] {
    margin-bottom: 0;
  }

  .subscriber-editor-questions-item span {
    flex: 0 0 35%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #222428;
  }

  .subscriber-editor-button {
    margin-top: 41px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    &.two-buttons {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .subscriber-editor-row-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }

  .subscriber-editor-phone {
    position: relative;
  }

  .subscriber-editor-phone-action {
    position: absolute;
    top: 50%;
    right: 20px;
    user-select: none;
    cursor: pointer;
    transform: translateY(-50%);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #0ba3a9;
  }

  .subscriber-editor-card {
    background: #ffffff;
    border: 1px solid rgba(166, 170, 180, 0.5);
    border-radius: 8px;
    padding: 24px;
  }

  .subscriber-editor-card-input {
    margin-bottom: 25px;
    display: flex;
    gap: 16px;
  }

  .subscriber-editor-card-input .base-input {
    margin-bottom: 0;
  }

  .subscriber-editor-card-input:last-child {
    margin-bottom: 0;
  }

  .subscriber-editor-card-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #0ba3a9;
    margin-bottom: 24px;
  }

  .subscriber-editor-remove {
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .subscriber-editor-remove-icon {
    height: 24px;
    width: 24px;
    border-radius: 100px;
    :deep(path) {
      fill: #f7685b;
    }
  }

  .subscriber-editor-card-button {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
    background: #0ba3a9;
    border-radius: 6px;
    padding: 8px 24px;
    cursor: pointer;
    display: inline-block;
  }

  .error-text {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #f7685b;
    margin-top: 30px;
    font-weight: 600;
  }

  .error-block {
    color: #de1534;
    padding: 10px 15px;
    border-radius: 5px;
    background: #ffedf3;
    line-height: 1.5;
    font-size: 14px;
    margin-bottom: 25px;
  }

  .stats-table {
    border: 1px solid rgba(166, 170, 180, 0.5);
    border-radius: 8px;
    width: 100%;
    border-collapse: separate;
    line-height: 1.5;
    overflow: hidden;

    &__row {
      &:not(:first-child) .stats-table__col {
        border-top: 1px solid rgba(166, 170, 180, 0.5);
      }
    }

    &__col {
      padding: 24px;
    }

    &__title {
      color: #a7aab3;
      white-space: nowrap;
    }

    &__text {
      width: 50%;
    }

    &__heading {
      background: #fafafa;
      color: #a8aab1;
      text-align: left;
      padding-top: 20px;
      padding-bottom: 20px;

      &.filter {
        transition: color 0.2s ease;
        cursor: pointer;

        &:hover {
          color: #0ba3a9;
        }

        &::after {
          content: "";
          display: inline-block;
          margin: 0 0 2px 4px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 3.5px 0 3.5px;
          border-color: currentColor transparent transparent transparent;
          transition: transform 0.2s ease;
        }

        &.is-ascending::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .sms-title {
    margin-top: 32px;
  }

  .no-sms-messages {
    color: #a7aab3;
  }
}
</style>
